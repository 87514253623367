.four-steps{
  >div{
    @extend .vertical-center-container;
    padding-bottom: 0;
    padding-top: 0;
    @include padding-left(.25);
    @include padding-right(.25);
  }
  .step{
    @extend .vertical-center;
    .media{
      padding: 0;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      .fa{
        @include font-size(3);
        color: #fff;
        @include margin-top(2.5);
      }
    }
    .content{
      margin: auto;
      h2{
        @include margin-top(.5);
        @include font-size($form-help-text-font-size);
        color: $secondary-font-color;
        font-weight: normal;
        display: inline-block;
      }
      h2::first-line{
        @include font-size($h2-font-size);
        color: $primary-color;
        font-weight: 400;
      }
    }

    &.one{
      .content{
        width: 110px;
      }
      .media{
        margin: auto;
        background-color: $step-1-color;
      }
    }
    &.two{
      .content{
        width: 160px;
      }
      .media{
        margin: auto;
        background-color: $step-2-color;
        line-height: 130%;
        color: #fff;
        span{
          @include margin-top(2.5);
          display: inline-block;
          font-family: $button-font-family;
          em{
            @include font-size(1.3);
            font-style: normal;
          }
        }
      }
    }
    &.three{
      .content{
        width: 140px;
      }
      .media{
        margin: auto;
        background-color: $step-3-color;
      }
    }
    &.four{
      .content{
        width: 146px;
      }
      .media{
        margin: auto;
        background-color: $step-4-color;
      }
    }
  }
}

@media screen and (max-width: $md-screen-breakpoint){
  .four-steps{
    .step{
      .media{
        height: 125px;
        width: 125px;
        .fa{
          @include margin-top(1.6);
        }
      }
      .content{
        h2::first-line{
          @include font-size($h2-font-size);
          span{
            margin-top: 0;
          }
        }
        h2{
          span{
            margin-top: 0;
          }
        }
      }
    }
    &.two{
      .media{
        span{
          @include margin-top(2);
        }
      }
    }
  }
}