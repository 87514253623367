/********************* MESSAGE SNIPPET ************** */
/**************************************************** */

.message-snippet {
  border-left: solid 6px #fff;
  display: block;

  .flex-container {
    display: -webkit-flex;
    display: flex;
    background-color: #fff;
    @include padding(1);
    .media {
      display: none;
    }
    .content {
      flex: 1;
      @include flex-display;
      justify-content: space-between;
      header {
        display: block;
        @include margin-right(.5);
        h1 {
          @include font-size($h4-font-size);
          @include margin-bottom(.25);
          line-height: 130%;
          color: $base-font-color;
          font-weight: normal;
          max-width: 100%;
        }
        .summary {
          @include font-size(.85);
          color: $secondary-font-color;
          line-height: 130%;
        }
      }
      .aux-info {
        @include flex-display();
        flex-direction: column;
        text-align: right;
        width: 35%; //Adding an arbitrary percentage so the the times are all in the same column
        time {
          margin-left: auto;
          @include font-size($form-help-text-font-size);
          color: $secondary-font-color;
        }
      }
    }
  }

  &:hover {
    border-left-color: $primary-color;
    text-decoration: none;
    .flex-container {
      .media {
        color: $primary-color !important;
      }
      .content {
        header {
          h1 {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.message-snippet.new {
  border-left-color: $success-font-color;
  &:hover {
    border-left-color: $primary-color;
  }
  .flex-container {
    .media {
      color: $success-font-color;
    }
    .content {
      header {
        h1 {
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (min-width: $md-screen-breakpoint) {
  .message-snippet {
    .flex-container {
      .media {
        display: inherit;
        width: 10%;
        @include font-size(2.5);
        color: $secondary-color;
        .fa {
          position: absolute;
        }
      }
      .content {
        header {
          @include margin-right(1);
        }
      }
    }
  }
}

//-------------
.conversation-container {
  margin-top: 0;
}

.message-snippet.new .flex-container .content .summary {
  color: #666;
}
