/* ************************** Badges ******************************** */
/* ****************************************************************** */



/******* Base Badge (Inline)*******/
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  @include font-size($badge-font-size);
  color: $badge-font-color;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: $badge-background-color;
  border-radius: 10px;

  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }
}

// Hover state, but only for links
a.badge {
  &:hover,
  &:focus {
    color: $badge-link-hover-font-color;
    text-decoration: none;
    cursor: pointer;
  }
}


/******* Menu Label Badge (Absolutely Positioned) *******/
.menu-badge{
  @extend .pulse;
  box-sizing:border-box;
  position: absolute;
  /*Eyballed values to get the position of the badge just right*/
  top:-15px;
  right:5px;
  min-height: 33px;
  min-width: 33px;
  background: $menu-badge-background-color;
  border:2px solid #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(0,0,0, .2);
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height:2.1em;
  cursor:default;
}

/*For sonar animation*/
.menu-badge:after {
  @extend .sonar;
  content: '';
  position: absolute;
  top:-2px;
  left:-2px;
  border:2px solid lighten($menu-badge-background-color, 10%);
  opacity:0;
  border-radius: 50%;
  width:100%;
  height:100%;
  animation-duration: 1.5s;
  animation-delay: 1;
}

@media screen and (max-width: $md-screen-breakpoint){
  .menu-badge{
    min-height: 28px;
    min-width: 28px;
    font-size: 12px;
  }
}
