.canned-query{
  a{
    display: block;
    color: #fff;
    div{
      @extend .hvr-fade;
      height: 200px;
      background-color: $highlighted-searches-background-color;
      text-align: center;
      span{
        display: inline-block;
        @include margin-top(4);
        @include margin-left(.5);
        @include margin-right(.5);
        @include font-size(1.2);
        line-height: 130%;
        text-transform: uppercase;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
      }
    }
    &:hover{
      div{
        background-color: darken($highlighted-searches-background-color, 10);
        span{
          // @include margin-top(5);
          -webkit-transform: translate3d(0,-20px,0);
          transform: translate3d(0,-20px,0);
        }
      }
    }
  }
  @for $i from 1 through $highlighted-searches-count{
    &.query-image-#{$i}{
      a{
        background: $institution-primary-color url('#{$highlighted-searches-images-path}/#{$i}.jpg');
        background-size: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }
  }
}
/*Canned Queries List*/
.canned-queries-list{
  @include margin-top(2);
}