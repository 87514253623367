/* ************* SECONDARY NAVIGATION DESIGN PATTERN *********************** */
/* Provides a secondary navigation for pages that need it. Three options are available: 1.Vertical, 2.Horizintal and 3.Tabbed */
/* ****************************************************************** */

/* Vertical Nav */
nav.secondary-nav.vertical{
  ul{
    list-style: none;
    padding-left: 0;
    li{
      @include margin-top(.5);
      @include padding(.75);
      background-color: $primary-color;
      line-height: 140%;
      a{
        color: #fff;
      }
    }
    li.active{
      background-color:$secondary-color;
    }
  }
}

/* Horizontal Nav */
nav.secondary-nav.horizontal{
  @include flex-display();
  line-height: 140%;
  text-align: center;
  position: relative;
  .fa.fa-check-circle{
      display: none;
  }

  a{
    // @extend .hvr-fade;
    -webkit-flex:1;
    flex:1;
    @include padding(.5);
    @include font-size(.95);
    @include no-hyphens();
    background-color: $primary-color;
    color:#fff;
    position:relative;

    &:hover{
      background-color: darken($primary-color, 10%);
      text-decoration: none;
    }
    &.active{
      background-color: $secondary-color;
      cursor:default;
      .fa.fa-check-circle{
        display: inline;
      }
      &:after{
        content: "";
        position: absolute;
        bottom: -15px;
        left: 50%;
        border-width: 15px 15px 0;
        border-style: solid;
        border-color: $secondary-color transparent;
        display: block;
        width: 0;
      }
    }
  }
}

@media screen and (min-width: $md-screen-breakpoint){
  nav.secondary-nav.horizontal{
    a{
      @include padding(1);
      @include font-size(1.1);
    }
  }
}
