/* SG
# Inclusion Exclusion Criteria block /

An Inclusion Exclusion Criteria block is used to capture inclusion/exclusion criteria added by the study team.

```
<section class="inclusion-exclusion-block" id="sectionSecondGroup">
  <header>
    <h4 class="hide">Group 2</h4>
  </header>
  <div class="no-criteria-message">
    No criteria set
  </div>
  <section class="inclusion-exclusion hide">
    <div class="inclusion">
      <strong class="sub-head"><span class="fa fa-check fa-margin-right success-font-color" aria-hidden="true"></span>Participants may be eligible if they meet <em>all</em> of the following criteria</strong>
      <ul>
        <li>
          Currently has Breast cancer
        </li>
        <li>
          Age (years) is greater than or equal to 18
        </li>
        <li>
          Must be willing to take experimental drugs
        </li>
      </ul>
    </div>
    <div class="exclusion">
      <strong class="sub-head"><span class="fa fa-times fa-margin-right error-font-color" aria-hidden="true"></span>Participants are not eligible if they meet <em>any</em> of the following criteria</strong>
      <ul>
        <li>
          Currently has Colon cancer
        </li>
        <li>
          Is pregnant at the time of enrollment
        </li>
      </ul>
    </div>
  </section>
  <section class="actions">
    <a data-history-navigate href="set-criteria.html?group=multiple" id="aSetCriteria" class="set-criteria"><span class="fa fa-pencil fa-margin-right"></span><span class="label">Edit</span></a>
    <a data-history-navigate href="" class="error-font-color"><span class="fa fa-trash fa-margin-right"></span><span class="">Delete</span></a>
  </section>
</section>
```
*/

.inclusion-exclusion-block{
  @extend .card;
  @include margin-top(1);
  header{
    @include margin-bottom(1);
    h4{
      line-height: 135%;
      font-weight: 500; 
    }
  }
  .inclusion-exclusion{
    @include font-size(1);
    ul{
      @include margin-top(.5);
      margin-bottom: 0;
      li {
        &:last-child {
          margin-bottom: 0;
        }

        list-style: disc;
        line-height: 135%;
        @include margin-top(1);
        @include margin-bottom(1);

        ul {
          @include padding-left(1.25);
          li {
            color: #333;
            list-style: circle;
            @include font-size($form-help-text-font-size);
            margin: 2px 0;
          }

        }
      }
    }
    .sub-head{
      display: block;
      line-height: 135%;
    }
    .inclusion{
      @include padding-bottom(1);
    }
    .exclusion{
      border-top: dotted 1px $border-color;
      @include padding-top(1);
    }
  }
  .actions{
    @include margin-top(2);
  }
  &:hover{
    @include box-shadow();
  }
}
