.typeahead-container{
  .tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .tt-input{
      margin-bottom: 0 !important;
  }

  .tt-hint {
    color: #999
  }

  .tt-menu {    /* used to be tt-dropdown-menu in older versions */
    //width: 422px;
    margin-top: 4px;
    padding: 4px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    overflow-y: scroll;
    max-height: 150px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }

  .tt-suggestion {
    padding: 3px 20px;
    line-height: 24px;
  }

  .tt-suggestion.tt-cursor,.tt-suggestion:hover {
    color: #fff;
    background-color: #0097cf;

  }

  .tt-suggestion p {
    margin: 0;
  }

  /******** RESULTS LIST **********/
  .tt-added-results{
    padding-left: 0;
    @include margin-bottom(1);
    ul{
      padding-left:0;
    }
    li{
      @extend .pulse;
      @include flex-display();
      align-items: center;
      list-style: none;
      border-bottom:dotted 1px $border-color;
      @include word-wrap();
      padding: .25em 0;
      @include font-size(.9);
      color: #333;
      &:last-child{
        border-bottom:0;
      }
      /*Styling the actual display text*/
      .display-text{
        max-width: 70%;
        line-height: 120%;
      }
      a{
        color: $error-font-color;
        margin-left: auto;
        .fa{
          display: none;
          @extend .hvr-fade;
        }
        &:hover{
          text-decoration: none;
          .fa{
            color: darken($error-font-color,15%);
          }
          .remove-label{
            text-decoration: underline;
          }
        }
      }
    }
  }
  /******** ACTION CONTAINER **********/

  .action-container{
    .twitter-typeahead{
      width: 100%;
    }
    //Make the typeahead and the not checkbox appear next to each other on bigger screens
    .badge-container{
      display: none;
    }
  }
}


@media screen and (min-width: $md-screen-breakpoint){
    .typeahead-container{
      /******** RESULTS **********/
      //Make the results appear as tags on medium+ screens
      .tt-added-results{
        li{
          display: inline-block;
          border-bottom:0;
          /*Tag styling. Not extending .tag because you can't in media queries*/
          @include font-size (.9);
          line-height: 120%;
          color: #333333;
          padding: 0.35em 0.56em 0.35em 0.56em;
          margin: 0 .25em 0.25em 0;
          background-color: $neutral-background-color;
          border: solid 1px $border-color;
          display: inline-block;
          @include border-radius($border-radius);
          white-space: normal;
          /*By default, the last item is set to not have any border. We do however, want a border for the last item on bigger screens*/
          &:last-child{
            border-bottom: solid 1px $border-color;
          }
          a{
            .fa{
              display: inline;
              @include font-size(1);
              @include margin-left(.5);
            }
            .remove-label{
              /*Show on screen readers but not on screen. Not extending show-for-sr because you can't in media queries*/
              clip: rect(1px, 1px, 1px, 1px);
              height: 1px;
              overflow: hidden;
              position: absolute !important;
              width: 1px;
            }
          }
        }
      }

      /******** ACTION CONTAINER **********/
      //Make the typeahead and the "not" checkbox appear next to each other on bigger screens
      .action-container{
          @include flex-display();
          align-items: center;
          // justify-content: center;
          .twitter-typeahead{
            width: auto;
          }
          .add-container{
            @include margin-right(.5);
          }
          .badge-container{
            display: block;
            @include margin-right(.5);
          }
          .not-container{
            label{
              /*Make the checkbox a block label. Not extending .block-label because you can't in media queries*/
              display: inline-block;
            	padding: .8em .9em;
            	background-color: $check-radio-background-color;
            	border:2px solid $check-radio-background-color;
            	@include border-radius($border-radius);
            	@include font-size(1);
            	font-weight:normal !important;
            	@include margin-bottom(.25);
              &:hover{
              	border:2px solid $form-field-focus-border-color;
              	cursor: pointer;
              }
            }
          }
      }
    }
}
