/* ************************* TAGS *********************************** */
/* ****************************************************************** */

 .tag{
  @include font-size (.9);
  line-height: 130%;
  color: #333333;
  padding: 0.35em 0.56em 0.35em 0.56em;
  margin: 0 .25em 0.25em 0;
  background-color: $neutral-background-color;
  border: solid 1px $border-color;
  display: inline-block;
  @include border-radius($border-radius);
  white-space: nowrap;
}

.tag_highlighted{
	background-color: yellow !important;
	font-weight: 700;
}
