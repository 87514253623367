.mobile-banner{
  .signup-section{
    @include font-size(.75);
    text-align: center;
    .small-special-button{
      @include font-size(.85);
      display: inline-block;
      @include margin-bottom(.25);
      @include margin-top(.5);
    }
    .why-signup{
      display: block;
    }
  }
}

@media screen and (min-width: $md-screen-breakpoint){
  .mobile-banner{
    display: none;
  }
}
