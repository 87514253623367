//medium screen sizes and up (we display small button). When mobile banner appears these styles wont apply
@media screen and (min-width: $md-screen-breakpoint){
  .language-picker, .language-picker select {
    padding: 0;
    height: 1.7rem;
    vertical-align: top;
    font-size: 0.8rem;
    display: inline-block;
  }

  .language-picker i.fa-globe {
    vertical-align: middle;
    padding-top: 0.2rem;
  }
}