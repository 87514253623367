/* Roboto Light latin */
$roboto-font-path:"../../../common-assets/fonts/roboto";

@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-light-300-latin.eot"); /* IE9 Compat Modes */
  src: local('Roboto Light'),
  local('Roboto-Light'),
  url("#{$roboto-font-path}/roboto-light-300-latin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-light-300-latin.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-light-300-latin.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-light-300-latin.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-light-300-latin.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-light-300-latin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto Light latin-ext */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-light-300-latin-ext.eot"); /* IE9 Compat Modes */
  src: local('Roboto Light'),
  local('Roboto-Light'),
  url("#{$roboto-font-path}/roboto-light-300-latin-ext.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-light-300-latin-ext.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-light-300-latin-ext.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-light-300-latin-ext.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-light-300-latin-ext.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-light-300-latin-ext.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto-Regular latin */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-regular-400-latin.eot"); /* IE9 Compat Modes */
  src: local('Roboto'),
  local('Roboto-Regular'),
  url("#{$roboto-font-path}/roboto-regular-400-latin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-regular-400-latin.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-regular-400-latin.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-regular-400-latin.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-regular-400-latin.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-regular-400-latin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto-Regular latin-ext */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-regular-400-latin-ext.eot"); /* IE9 Compat Modes */
  src: local('Roboto'),
  local('Roboto-Regular'),
  url("#{$roboto-font-path}/roboto-regular-400-latin-ext.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-regular-400-latin-ext.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-regular-400-latin-ext.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-regular-400-latin-ext.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-regular-400-latin-ext.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-regular-400-latin-ext.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto-Italic latin */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-italic-400-latin.eot"); /* IE9 Compat Modes */
  src: local('Roboto Italic'),
  local('Roboto-Italic'),
  url("#{$roboto-font-path}/roboto-italic-400-latin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-italic-400-latin.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-italic-400-latin.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-italic-400-latin.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-italic-400-latin.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-italic-400-latin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: italic;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto-Italic latin-ext */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-italic-400-latin-ext.eot"); /* IE9 Compat Modes */
  src: local('Roboto Italic'),
  local('Roboto-Italic'),
  url("#{$roboto-font-path}/roboto-italic-400-latin-ext.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-italic-400-latin-ext.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-italic-400-latin-ext.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-italic-400-latin-ext.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-italic-400-latin-ext.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-italic-400-latin-ext.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: italic;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto-Medium latin */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-medium-500-latin.eot"); /* IE9 Compat Modes */
  src: local('Roboto Medium'),
  local('Roboto-Medium'),
  url("#{$roboto-font-path}/roboto-medium-500-latin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-medium-500-latin.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-medium-500-latin.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-medium-500-latin.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-medium-500-latin.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-medium-500-latin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto-Medium latin-ext */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-medium-500-latin-ext.eot"); /* IE9 Compat Modes */
  src: local('Roboto Medium'),
  local('Roboto-Medium'),
  url("#{$roboto-font-path}/roboto-medium-500-latin-ext.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-medium-500-latin-ext.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-medium-500-latin-ext.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-medium-500-latin-ext.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-medium-500-latin-ext.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-medium-500-latin-ext.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto-Bold latin */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-bold-700-latin.eot"); /* IE9 Compat Modes */
  src: local('Roboto Bold'),
  local('Roboto-Bold'),
  url("#{$roboto-font-path}/roboto-bold-700-latin.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-bold-700-latin.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-bold-700-latin.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-bold-700-latin.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-bold-700-latin.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-bold-700-latin.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto-Bold latin-ext */
@font-face {
  font-family: "Roboto";
  src: url("#{$roboto-font-path}/roboto-bold-700-latin-ext.eot"); /* IE9 Compat Modes */
  src: local('Roboto Bold'),
  local('Roboto-Bold'),
  url("#{$roboto-font-path}/roboto-bold-700-latin-ext.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-bold-700-latin-ext.otf") format("opentype"), /* Open Type Font */
  url("#{$roboto-font-path}/roboto-bold-700-latin-ext.svg") format("svg"), /* Legacy iOS */
  url("#{$roboto-font-path}/roboto-bold-700-latin-ext.ttf") format("truetype"), /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-bold-700-latin-ext.woff") format("woff"), /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-bold-700-latin-ext.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
