body > footer {
	background-color: $institution-primary-color;
	@include padding(1);
	@include margin-top(2);

	nav{
		ul{
			padding-left: 0;
			li{
				@include margin-top(.5);
				list-style: none;
				a{
					display: inline-block;
					color:#fff;
					line-height: 120%;
					&:hover{
						color: #fff;
					}
				}
			}
		}
	}
	.contact-card{
		background-color: #fff;
		border-top:8px solid $institution-secondary-color;
	}
	.social{
		text-align: center;
		a{
			color: rgba(#fff,.7);
			@include font-size(1.5);
			@extend .hvr-fade;
			&:hover{
				color: rgba(#fff,1);
			}
		}
	}

	.logos{
		border-top: dotted 1px rgba(#fff,.3);
		@include padding-top(1);

		.department-logo{
			display: block;
			background: url(#{$department-logo-filename}) no-repeat scroll center transparent;
			width: 100%;
			height: 80px;
		}
		.powered-by{
			display: block;
			background: url(../../../common-assets/images/logo-powered-by.png) no-repeat scroll center transparent;
			height: 47px;
		}
	}

	.copyright,
	.admin-login{
		color: rgba(#fff,.8);
		@include font-size(.7);
		text-align: center;
	}
	.admin-login{
		a{
			color: rgba(#fff,.8);
		}
		.fa:hover{
			cursor: help;
		}
	}
	.heap-badge{
		width: 100%;
		text-align: center;
		@include margin-bottom(3);
	}
}

@media screen and (min-width: $md-screen-breakpoint){
	body > footer{
		.logos{
			.department-logo{
				text-align: left;
				background-position: top left;
			}
			.powered-by{
				text-align: right;
				background-position: top right;
			}
		}
		.copyright,
		.admin-login{
			text-align: right;
		}
		.heap-badge{
			text-align: right;
			margin-bottom:0;
		}
	}
}