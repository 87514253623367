/**
 *
 * =======================================================================
   Colors
 * =======================================================================
 */

//Primary colors. Mostly applied in buttons
$primary-color:  #202020;
$secondary-color: #e8c82a;
$special-color: #51cf83;
$accent-color: #3f51b5;

//Institution specific colors. Each institution can customize their colors here.
//$institution-primary-color: #252525;
$institution-primary-color: #282dfc;
$institution-secondary-color: #ebebeb;

//Homepage hero section search button background color
$homepage-search-background-color: $institution-primary-color;

//4 steps colors
$step-1-color:$institution-primary-color;
$step-2-color:$special-color;
$step-3-color:$secondary-color;
$step-4-color:$success-font-color;

//Cookie consent banner style options
$cookie-consent-banner-background-color: rgb(74,71,72); //another option could be $primary-color
$cookie-consent-banner-font-color: #fff;
$cookie-consent-banner-button-background-color:$secondary-color;
$cookie-consent-banner-button-font-color:$primary-color;
$cookie-consent-banner-link-color:#fff;

//Homepage highlighted searches
$highlighted-searches-background-color: rgba($institution-primary-color, 0.65);
$highlighted-searches-count:12;
$highlighted-searches-images-path: "../images/highlighted-searches";

/**
 *
 * =======================================================================
   IMAGE PATHS & FILENAMES
 * =======================================================================
 */
//****** Logos ******//
$logo-small-filename: "../images/logo-medium.png";
$logo-filename: "../images/logo-medium.png";
$department-logo-filename:"../images/logo-footer.png";

//****** Victor section on homepage ******//
$victor-section-images-count: 1;
$victor-section-images-path: "../images/victor-images";