.attachments{
  @include margin-top(1.5);
  // @include padding-top(1);
  // border-top: dotted 1px $border-color;
  .attachment{
    @extend .tag;
    color: $link-font-color;
    @include font-size(1);
    font-weight: 500;

    &:hover{
      background-color: $special-background-color;
    }
  }
}
