/* ************* STUDY SNIPPET DESIGN PATTERN *********************** */
/* Extends card design pattern */
/* ****************************************************************** */


.study-snippet{
		@extend .card;
		//Overview for small devices
		.small-overview{
			text-align: right;
			@include font-size(1.2);
			@include margin-bottom(1);
		}
		.study-overview{
			display: none;
		}
		div{
			.fa{
				@include margin-right(0.2);
			}
		}
		.study-snippet-summary{
			background: $neutral-background-color;
			padding:.5em;
			@include font-size(0.850);
			text-align: left;
			@include border-radius($card-border-radius);
		}
		.primary-button{
			@include margin-bottom(1);
		}
}

/*A study card can have two banners - "New" and "Currently not enrolling". The "New" styling is extended from the base card while specific styling for "Currently not enrolling" is specified here*/
.study-snippet.not-enrolling{
	border-top-color:$error-font-color;
	.banner{
		color: $error-font-color;
	}
}

// For medium screen sizes and up
@media screen and (min-width: $md-screen-breakpoint){
	.study-snippet{
		.study-overview{
			@include flex-display();
			@include padding(.5);
			.info-block{
				-webkit-flex:1;
				flex:1;
				@include margin-bottom(0.2);
				padding: 0.5em;
				text-align: center;
				vertical-align: middle;
				border-right:dashed 1px darken( $border-color, 20% );
				@include font-size(.9);
				color: $secondary-font-color;
				line-height: 135%;
				.fa{
					@include font-size(1.5);
					color: $secondary-color;
				}
				.age{
					font-weight: 700;
					@include font-size(1.25);
				}
				//hack to not show dotted line for last element. Does not work in IE8
				&:last-child{
					border-right: 0px;
				}
			}
		}
	}

	/* Styling for "Currnetly not enrolling"*/
	.study-snippet.not-enrolling{
		border-left-color:$error-font-color;
		.banner{
			color: $error-font-color;
		}
	}
}
