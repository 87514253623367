/**
 *
 * =======================================================================
   Font Sizes
 * =======================================================================
 */

@use "sass:math";

$one-rem:18; //base-font-size in px
$base-font-size: math.div($one-rem, 16); //base font size in proportion to default browser font size
$base-line-height:1.90; //165%;

// Typographic scale
$h1-font-size:1.6180;
$h2-font-size:1.4382;
$h3-font-size:1.2222;
$h4-font-size:1.1111;

//Extra large font (used mostly for extra large icons)
$extra-large-font-size:5;

//Forms
$form-help-text-font-size: 0.850;
$form-legend-font-size: 1.2222;

//Badges
$badge-font-size:0.850;
/**
 *
 * =======================================================================
   Colors
 * =======================================================================
 */

//Primary colors. Mostly applied in buttons
$primary-color: #366caf; //blue
$secondary-color: #FF9800; //orange
$special-color: purple; //purple
$accent-color: #3f51b5;

//Institution specific colors. Each institution can customize their colors here.
$institution-primary-color: #00274c;
$institution-secondary-color: #ffcb05;

//Font colors
$header-font-color:#222; // Default color applied to all headers
$h1-font-color: #222; //h1 font color overrides default header color
$h4-font-color: #666; //h1 font color overrides default header color
$base-font-color:#333;
$secondary-font-color: #666;
$small-font-color: #555;
$link-font-color: #0362cf;
$link-hover-color: $primary-color;
$success-font-color: green;
$error-font-color: #de1a0c;
$feedback-block-font-color: #484848;
$badge-font-color: #ffffff;
$badge-link-hover-font-color:$secondary-color;

// Background colors
$neutral-background-color:#f2f2f2; //gray background
$special-background-color:#D7E5F1; //light blue background
$success-background-color:#d4ece6; //light green background color for success feedback blocks
$error-background-color:#f8d0c8; //light red background color for error feedback blocks
$info-background-color:#ffeebf; //yellow background color for info feedback blocks
$badge-background-color: $success-font-color; //green background color for badges
$menu-badge-background-color: $success-font-color; //background color for menu badges
$field-background-color: #fff; //field background color
$check-radio-background-color: $neutral-background-color; // Background color for checkboxes and radio buttons
$message-background-color:$neutral-background-color; //message background color
$message-background-color-alternate:$special-background-color;

// Border colors
$border-color:#ccc;
$form-field-border-color:#999;
$form-field-focus-border-color:$primary-color;

//Overlay colors
$overlay-color:rgba(89, 141, 204, 0.95);

//Cookie Consent Banner colors
$cookie-consent-banner-background-color: $accent-color;
$cookie-consent-banner-font-color: #fff;
$cookie-consent-banner-button-background-color:$institution-secondary-color;
$cookie-consent-banner-button-font-color:$institution-primary-color;
$cookie-consent-banner-link-color:#fff;

/**
 *
 * =======================================================================
   Font families
 * =======================================================================
 */
$primary-font-family: "Roboto",Arial,sans-serif;
$header-font-family:  "Roboto",Arial,sans-serif;
$button-font-family:  "Helvetica",Arial,sans-serif;


// Border radius
$border-radius: 5px;
$card-border-radius: 2px;

/**
 *
 * =======================================================================
   IMAGE PATHS & FILENAMES
 * =======================================================================
 */
// Defined in theme files

//****** Logos ******//
$logo-small-filename: "";
$logo-filename: "";
$department-logo-filename:"";

$image-path: "../../../common-assets/images";

//****** Victor section on homepage ******//
// There can be as many rotating images as you like. All files must be .jpg. The image files must be named "1.jpg", "2.jpg" etc. If you want to choose images other than the default ones, you can specify the path where your images are stored via this variable.images
$victor-section-images-count: 8; // This is the default number of images. Each theme can override this number in their theme's "variables" file.
$victor-section-images-path: "#{$image-path}/victor-images";

//****** Highlighted Searches on homepage ******//
$highlighted-searches-images-path: "#{$image-path}/canned-queries";
$highlighted-searches-count: 12;

//Volunteer Profile background colors
$volunteer-profile-background-color: $institution-secondary-color;

//Homepage 4 steps circle colors
$step-1-color:$institution-secondary-color;
$step-2-color:$special-color;
$step-3-color:$secondary-color;
$step-4-color:$success-font-color;

//Volunteer Profile background colors
$volunteer-profile-background-color: $institution-secondary-color;

/**
 *
 * =======================================================================
   Responsive Breakpoints (Min. widths)
 * =======================================================================
 */
$sm-screen-breakpoint: 17.777em;
$md-screen-breakpoint: 42.666em;
$lg-screen-breakpoint: 56.888em;
$xl-screen-breakpoint: 71.111em;
