header.stripped-header .logo{
	@include margin-top(0);
	background: url(#{$logo-small-filename}) no-repeat scroll 50% 0 transparent;
	height:75px;
	@include center();
}

//medium screen sizes and down (we display mobile banner only)
@media screen and (max-width: $md-screen-breakpoint){
	header.stripped-header{
		.logo{
			display: none;
		}
	}
}