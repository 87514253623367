/* ************** YHR-SPECIFIC LOGIN SCREEN ************* */
/* ******************************************************* */
.login .logo{
	background: url(#{$logo-small-filename}) no-repeat scroll 50% 0 transparent;
	height:75px;
}

.loginHelp{
	background-color: #fff;
	border: solid 1px $border-color;
	@include border-radius($border-radius);
	padding: 1em;
	@include margin-bottom(1.5);
}

.loginHelp .fa{
	@include margin-right(.2);
}
