/* *********************** POPUP STYLING *********************** */
/* ************************************************************* */
// Authored for jquery plugin jQuery Popup Container. The plugin adds a class "popup_content" to the modal window when the window is active

.popup_content {
    min-height: 1.2em;
    margin: 0;
    background-color: #fff;
    @include border-radius($border-radius);
    -webkit-box-shadow: inset 0 0.05em 0.05em rgba(0,0,0,.05);
    box-shadow: 0px 15px 20px 0px rgba(0,0,0,0.25);

    header,
    footer{
      @include padding(1);
      @include padding-top(1.5);
  		background-color: $neutral-background-color;
  		border-bottom: solid 1px lighten($border-color, 10%);
      display: -webkit-flex;
      /* -webkit-flex-flow: row wrap; */
      display: -ms-flexbox;
      -ms-flex-flow: row wrap;
      display: flex;
      flex-flow: row wrap;
      .form-stacked {
        label{
          display: inline;
        }
        .button-container{
          button[type="submit"],
          a[class$="button"]
            {
              @include margin-top(0);
            }
          }
        }
    }
    header {
      background-color: $accent-color;
      h1{
        width:90%;
        @include margin-bottom(0);
        @include font-size($h3-font-size);
        color: #fff;
        font-weight: normal;
      }
      .top-close-button{
        width:10%;
        text-align: right;
        a span{
          font-size: 1.4382em;
          font-weight: bold;
          color: #fff;
        }
      }
    }

    section.popup-main{
      @include padding(1);

      // Hack to make select boxes within the popup 80% so that they do not expand beyond the popup width even if they have very large options
      form select{
        width: 80%;
      }
    }

    footer{
      .form-stacked{
        .button-container{
          [disabled].primary-button{
            border: solid 1px darken($neutral-background-color,25%);
            color: darken($neutral-background-color,25%) !important;
          }
        }
      }
    }

}

// For medium screen sizes and up
@media screen and (min-width: $md-screen-breakpoint){
  .popup_content {
      margin: 1em;
      max-width:40em;
      header,
      footer,
      section.popup-main{
        @include padding-left(2);
        @include padding-right(2);
      }
    }
}
