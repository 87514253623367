.volunteer-list{
  .volunteer-list-header h1{
  	@include margin-top(.264);
    @include margin-bottom(2.264);
  	font-weight: normal;
    color: #666;
    font-weight: 300;
    @include font-size($h2-font-size);
    text-align: center;
  }
  ul,ol{
    list-style: none;
    padding:0;
  }
  li{
  	@include margin-bottom(0.2);
  }
  li:last-child{
  	@include margin-bottom(0);
  }
}

// For medium screen sizes and up
@media screen and (min-width: $md-screen-breakpoint){
  .volunteer-list{
    .volunteer-list-header h1{
      @include font-size($h1-font-size);
      @include margin-bottom(3);
    }
    ol,li{
      padding:0;
    }
    li{
    	@include margin-bottom(0.3);
    }
  }
}
